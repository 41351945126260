import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import DownloadSvgIcon from '@core/assets/icons/download.svg';
import colors from '@core/constants/colors';

export const Container = styled.div`
  background-color: ${colors.lightGrey};
  padding: 24px;
`;

export const Heading = styled(Typography).attrs({ type: 'h2' })`
  margin-bottom: 28px;
`;

export const Material = styled.a`
  display: grid;
  align-items: center;
  grid-template-columns: 1fr auto;
  column-gap: 8px;
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.mystic};
  margin-bottom: 12px;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FileName = styled(Typography).attrs({
  type: 'p1',
  color: 'chambrayBlue',
  weight: 'bold',
})`
  line-height: 20px;
  margin-bottom: 2px;
`;

export const DownloadIcon = styled(DownloadSvgIcon)`
  color: ${colors.riverBlue};
  cursor: pointer;
  flex-basis: 20px;
`;
