import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors, { TColors } from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import { ISectionHeaderStylesProps } from './types';

export const SectionHeader = styled(Typography).attrs({ type: 'h1' })<ISectionHeaderStylesProps>`
  color: ${({ color }) => colors[color as TColors]};
  text-align: center;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 28px;
    line-height: 34px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 40px;
    line-height: 40px;
  }
`;
