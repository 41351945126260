import { Typography } from '@core/UI/Typography';

import * as UI from './Goal.styles';
import { goals } from './data';

const Goal = () => (
  <UI.Container>
    <UI.GoalHeader>цель до 2027 год</UI.GoalHeader>

    <UI.GoalText>сократить не менее чем на</UI.GoalText>
    <UI.GoalList>
      {goals.map((goal) => (
        <UI.Goal key={goal.id}>
          <UI.Number tag="span">{goal.number}</UI.Number>
          <UI.GoalWrapper>
            <Typography color="cadetBlue" type="p2">{goal.value}</Typography>
            <Typography color="white" tag="span" type="h3" weight="normal">{goal.subValue}</Typography>
          </UI.GoalWrapper>
        </UI.Goal>
      ))}
    </UI.GoalList>
  </UI.Container>
);

export default Goal;
