import { memo } from 'react';

import * as UI from './RegulatoryWork.styles';
import LegalDocuments from './components/LegalDocuments';
import StudyMaterials from './components/StudyMaterials';
import { IRegulatoryWorkProps } from './types';

const RegulatoryWork = (props: IRegulatoryWorkProps) => {
  const { isPublic, styles } = props;

  return (
    <UI.Container isPublic={isPublic} styles={styles}>
      <div>
        <UI.Heading>
          Нормативно-правовая работа
        </UI.Heading>

        <LegalDocuments isPublic={isPublic} />
      </div>

      {isPublic && (
        <StudyMaterials isPublic={isPublic} />
      )}
    </UI.Container>
  );
};

export default memo(RegulatoryWork);
