import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import { IWrapperStylesProps } from './types';

export const Wrapper = styled.div<IWrapperStylesProps>`
  background: linear-gradient(249.71deg, ${colors.chambrayBlue} 2.28%, transparent 56.54%), #083158;

  padding-bottom: 590px;

  @media ${BREAKPOINTS.TABLET} {
    padding-top: 10px;
    padding-bottom: 420px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    padding-top: 55px;
    padding-bottom: 165px;
  }

  ${({ styles }) => styles};
`;

export const Title = styled(Typography).attrs({ color: 'white', type: 'h1' })`
  margin-bottom: 50px;
  font-size: 24px;
  line-height: 35px;
  text-align: center;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 40px;
    font-size: 28px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    margin-bottom: 75px;
    font-size: 40px;
  }
`;
