import { IFileInfo } from '@core/store/modules/materials/types';

export enum EPreviewMimeTypes {
  image = 'image',
  video = 'video',
}

export interface IPreviewProps {
  file: IFileInfo | null;
  isPublic?: boolean;
  withDownloadButton?: boolean;
  withHeader?: boolean;
}
