import { memo, useCallback, useLayoutEffect, useState } from 'react';

import BannerPopup from '@core/UI/BannerPopup';
import { LINKS } from '@core/constants/links';
import { useAuthenticated } from '@core/services/Auth/hooks/useAuthenticated';
import { getLocalStorageItem, setLocalStorageItem } from '@core/utils/localStorage';

import bannerImage from './assets/m12.png';

const STORAGE_KEY = 'IS_BANNER_M12_CLOSED';

const PromoBannerPopup = () => {
  const [isShown, setIsShown] = useState(false);
  const isAuth = useAuthenticated();

  const onClose = useCallback(() => {
    setLocalStorageItem(STORAGE_KEY, true);
  }, []);

  useLayoutEffect(() => {
    setIsShown(!getLocalStorageItem(STORAGE_KEY));
  }, []);

  return isShown ? (
    <BannerPopup
      alt="M-12 - Дорога возможностей"
      link={isAuth ? LINKS.M12 : '#media'}
      src={bannerImage}
      onClose={onClose}
    />
  ) : null;
};

export default memo(PromoBannerPopup);
