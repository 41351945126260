import Image from 'next/image';
import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS, DEVICES } from '@core/constants/styles';
import { hexToRGB } from '@core/utils/styles';

export const QuoteTypography = styled(Typography).attrs({ type: 'p1' })`
  padding-right: 10px;

  @media ${BREAKPOINTS.TABLET} {
    padding-right: 0;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 20px;
    line-height: 27px;
  }
`;

export const NameTypography = styled(Typography).attrs({ type: 'p2' })`
  margin-top: 16px;
  width: 174px;
  font-weight: bold;
  font-size: 14px;
  line-height: 20px;

  @media ${BREAKPOINTS.TABLET} {
    width: auto;
    margin-top: 20px;
    padding: 0;
    font-size: 18px;
    line-height: 22px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: 252px;
    margin-top: 24px;
  }
`;

export const PositionTypography = styled(Typography).attrs({ type: 'p2' })`
  margin-top: 4px;
  width: 174px;
  color: ${colors.cadetBlue};

  @media ${BREAKPOINTS.TABLET} {
    width: auto;
    padding: 0;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: 252px;
    margin-top: 8px;
  }
`;

export const Quote = styled.div`
  position: relative;
  padding: 16px 24px;
  background: linear-gradient(
      270deg,
      ${hexToRGB(colors.chambrayBlue, 0.2)} -9.4%,
      ${hexToRGB(colors.chambrayBlue, 0)} 73.39%
    ),
    ${colors.white};
  box-shadow: 8px 10px 30px ${hexToRGB(colors.chambrayBlue, 0.1)};
  border-radius: 12px;
  overflow: hidden;

  @media ${DEVICES.MOBILE} {
    margin-top: 24px;
  }

  @media ${BREAKPOINTS.TABLET} {
    padding: 24px 124px 24px 32px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    flex-shrink: 0;
    width: 468px;
    padding: 24px 32px;
  }
`;

export const TelegramContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 20px;
  margin-top: 16px;
`;

export const TelegramQrCode = styled(Image)`
  display: none;
  width: 80px;
  height: 80px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: block;
  }
`;

export const TelegramInfo = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TelegramLabel = styled(Typography).attrs({ type: 'p2', color: 'manatee' })``;

export const TelegramId = styled(Typography).attrs({ type: 'p2', color: 'chambrayBlue' })``;

export const Khusnullin = styled(Image)`
  position: absolute;
  bottom: 0;
  right: 0;
  width: 88px;
  height: 132px;

  @media ${BREAKPOINTS.TABLET} {
    width: 124px;
    height: 186px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    bottom: -56px;
    width: 204px;
    height: 307px;
  }
`;
