import { useMemo } from 'react';

import { EPlaceholderIds } from '@core/constants/placeholders';

import { usePlaceholder } from './usePlaceholder';

interface IVideoAntiCrisisProgram {
  id: number,
  preview: number,
  video: number,
  visible: boolean,
}

export const useVideosAntiCrisisProgram = () => {
  const preview1 = usePlaceholder(EPlaceholderIds.ANTI_CRISIS_PREVIEW_1);
  const video1 = usePlaceholder(EPlaceholderIds.ANTI_CRISIS_VIDEO_1);
  const preview2 = usePlaceholder(EPlaceholderIds.ANTI_CRISIS_PREVIEW_2);
  const video2 = usePlaceholder(EPlaceholderIds.ANTI_CRISIS_VIDEO_2);

  const hasVideo1 = Boolean(preview1?.isActive && video1?.isActive && preview1?.file && video1?.file);
  const hasVideo2 = Boolean(preview2?.isActive && video2?.isActive && preview2?.file && video2?.file);

  return useMemo(() => [
    hasVideo1 && { id: 0, preview: preview1.file.id, video: video1.file.id, visible: hasVideo1 },
    hasVideo2 && { id: 1, preview: preview2.file.id, video: video2.file.id, visible: hasVideo2 },
  ].filter((item) => !!item) as IVideoAntiCrisisProgram[],
  [hasVideo1, hasVideo2, preview1, preview2, video1, video2]);
};
