import { useState, memo } from 'react';

import CloseIcon from '@core/assets/icons/close_with_shadow.svg';

import * as UI from './BannerPopup.styles';
import { IPopupProps } from './types';

const BannerPopup = (props: IPopupProps) => {
  const { src, alt, link, onClose, shouldOpenNewTab = false } = props;

  const [isOpen, setIsOpen] = useState(true);

  const onClickClose = () => {
    onClose();

    setIsOpen(false);
  };

  return (
    <UI.Container isOpen={isOpen}>
      <UI.BannerLink
        href={link}
        rel={shouldOpenNewTab ? 'noopener noreferrer' : undefined}
        target={shouldOpenNewTab ? '_blank' : undefined}
      >
        <UI.BannerImage alt={alt} loading="lazy" src={src} />
      </UI.BannerLink>
      <UI.CloseButton onClick={onClickClose}>
        <CloseIcon />
      </UI.CloseButton>
    </UI.Container>
  );
};

export default memo(BannerPopup);
