import { memo, useState } from 'react';
import { useSelector } from 'react-redux';

import { useModal } from '@eisgs/modal';
import { Preloader } from '@eisgs/preloader';

import { EModalNames } from '@core/UI/Modal/constants';
import { ESortDirection } from '@core/UI/Table/types';
import { Typography } from '@core/UI/Typography';
import { useAppDispatch } from '@core/store';
import { EFileType, IFileInfo } from '@core/store/modules/materials/types';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';

import Preview, { checkPreviewMimetype } from '@features/adminPanel/components/Files/components/Preview';

import * as UI from './LegalDocuments.styles';
import { fetchLegalDocuments, selectLegalDocuments } from './store';
import { ILegalDocumentsProps } from './types';

const tabs = [
  { label: 'Федеральные законы', id: EFileType.FEDERAL_LAW },
  { label: 'Постановления, распоряжения Правительства РФ', id: EFileType.DECREE },
];

const LegalDocuments = (props: ILegalDocumentsProps) => {
  const { isPublic } = props;
  const modal = useModal();
  const dispatch = useAppDispatch();
  const {
    data: documents,
    meta: {
      fetchLoading,
      fetchSuccess,
    },
  } = useSelector(selectLegalDocuments);

  const [activeTabId, setActiveTabId] = useState(tabs[0].id);
  const [documentToPreview, setDocumentToPreview] = useState<IFileInfo | null>(null);

  const openDocumentPreview = (document: IFileInfo) => () => {
    setDocumentToPreview(document);
    modal.open(EModalNames.PREVIEW_FILE);
  };

  const handleClickTab = (id: EFileType) => () => {
    setActiveTabId(id);
    dispatch(fetchLegalDocuments({
      type: id,
      sortingField: 'createdAt',
      sortingDirection: ESortDirection.DESC,
      isPublic,
    }));
  };

  return (
    <div>
      <UI.Tabs>
        {tabs.map(
          ({ id, label }) => (
            <UI.Tab
              isActive={activeTabId === id}
              key={id}
              onClick={handleClickTab(id)}
            >
              <Typography type="p1">
                {label}
              </Typography>
            </UI.Tab>
          ),
        )}
      </UI.Tabs>

      <UI.ActiveTabContent>
        <UI.Heading>
          {tabs.find(({ id }) => id === activeTabId)?.label}
        </UI.Heading>

        {fetchLoading && (
          <Preloader
            size={48}
            styles={UI.PreloaderStyles}
          />
        )}

        {fetchSuccess && documents && (
          <UI.DocumentsList>
            <div>
              {documents.map((document) => {
                const { id, title, name, description } = document;

                const documentInfo = (
                  <>
                    <UI.DocumentName>
                      {title || name}
                    </UI.DocumentName>
                    {description && (
                      <UI.DocumentDescription color="riverBlue" type="p2">
                        {description}
                      </UI.DocumentDescription>
                    )}
                  </>
                );

                if (isPublic) {
                  return (
                    <UI.Document
                      interactive={false}
                      key={id}
                    >
                      {documentInfo}
                    </UI.Document>
                  );
                }

                return checkPreviewMimetype(document)
                  ? (
                    <UI.Document
                      key={id}
                      onClick={openDocumentPreview(document)}
                    >
                      {documentInfo}
                    </UI.Document>
                  )
                  : (
                    <UI.Document
                      as="a"
                      href={isPublic ? getPublicFileUrl(id) : getFileUrl(id)}
                      key={id}
                    >
                      {documentInfo}
                    </UI.Document>
                  );
              })}
            </div>
          </UI.DocumentsList>
        )}
      </UI.ActiveTabContent>

      <Preview
        withDownloadButton
        file={documentToPreview}
        isPublic={isPublic}
        withHeader={false}
      />
    </div>
  );
};

export default memo(LegalDocuments);
