import Modal from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';

import { IVideoModalProps } from '@features/strategy/components/AntiCrisisProgram/components/VideoModal/types';

import * as UI from './VideoModal.styles';

const VideoModal = (props: IVideoModalProps) => {
  const { videoId, isPublic } = props;

  return (
    <Modal
      name={EModalNames.ANTI_CRISIS_VIDEO}
      styles={UI.VideoModalStyles}
    >
      <UI.VideoPreview
        controls
        url={isPublic ? getPublicFileUrl(videoId) : getFileUrl(videoId)}
      />
    </Modal>
  );
};

export default VideoModal;
