import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

import braceDesktop from '@features/landing/assets/images/brace_desktop.png';

import { Indicator, IndicatorList } from './components/IndicatorList/IndicatorList.styles';

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 54px;
  align-items: end;

  @media ${BREAKPOINTS.TABLET} {
    gap: 46px;
    align-items: center;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    flex-direction: row;
    justify-content: space-between;
    gap: 10px;

    &::after {
      content: '';
      position: absolute;
      right: 360px;
      width: 28px;
      height: 100%;
      background: url(${braceDesktop.src}) no-repeat;
      background-size: cover;
    }
  }
`;

export const Signature = styled.div`
  margin-top: 115px;
  gap: 10px;
  display: flex;
  flex-direction: column;

  ${Typography} {
    display: flex;
    align-items: center;
    height: 66px;

    @media ${BREAKPOINTS.TABLET} {
      padding-bottom: 8px;
      font-size: 16px;
      align-items: end;
    }

    @media ${BREAKPOINTS.DESKTOP_LG} {
      padding: 0px;
      align-items: center;
    }
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: 105px;
  }
`;

export const SignatureMobile = styled(Signature)`
  margin-top: 123px;

  @media ${BREAKPOINTS.TABLET} {
    display: none;
  }
`;

export const IndicatorContainer = styled.div`
  display: flex;
  align-items: start;
  gap: 0px 10px;

  @media ${BREAKPOINTS.DESKTOP} {
    gap: 0px 40px;
  }
`;

export const IndicatorTotalContainer = styled.div`
  width: 100%;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: 315px;

    ${Indicator} {
      width: auto;
    }
  }

  ${IndicatorList} {
    gap: 60px;
    justify-content: center;

    @media ${BREAKPOINTS.TABLET} {
      gap: 160px;
    }

    @media ${BREAKPOINTS.DESKTOP_LG} {
      gap: 60px;
      justify-content: space-between;
    }
  }
`;

export const IndicatorTotalTitle = styled(Typography).attrs({ type: 'p1', transform: 'uppercase', color: 'white', lineHeight: 62 })`
  margin-bottom: 20px;
  text-align: center;
  background-color: #2E5B8E;
  border-radius: 16px;

  @media ${BREAKPOINTS.TABLET} {
    max-width: 530px;
    margin: 0px auto 40px auto;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-bottom: 15px
  }
`;
