import { Typography } from '@core/UI/Typography';

import * as UI from './TotalIndicators.styles';
import IndicatorList from './components/IndicatorList';
import { indicators, totalIndicators } from './data';

const TotalIndicators = () => (
  <UI.Container>
    <UI.IndicatorContainer>
      <div>
        <UI.Signature>
          <Typography color="white" transform="uppercase" type="p2">Было</Typography>
          <Typography color="vividTangerine" transform="uppercase" type="p2">Стало</Typography>
        </UI.Signature>
        <UI.SignatureMobile>
          <Typography color="white" transform="uppercase" type="p2">Было</Typography>
          <Typography color="vividTangerine" transform="uppercase" type="p2">Стало</Typography>
        </UI.SignatureMobile>
      </div>
      <IndicatorList list={indicators} />
    </UI.IndicatorContainer>

    <UI.IndicatorTotalContainer>
      <UI.IndicatorTotalTitle>Всего</UI.IndicatorTotalTitle>
      <IndicatorList list={totalIndicators} />
    </UI.IndicatorTotalContainer>
  </UI.Container>
);

export default TotalIndicators;
