import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 46px;

  @media ${BREAKPOINTS.TABLET} {
    margin-top: 55px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-top: 70px;
  }
`;

export const GoalHeader = styled(Typography).attrs({ type: 'p2', color: 'white', lineHeight: 32, transform: 'uppercase' })`
  text-align: center;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 20px;
  }
`;

export const GoalText = styled(Typography).attrs({ type: 'h1', color: 'vividTangerine', lineHeight: 30 })`
  font-size: 28px;
  text-align: center;
  margin-bottom: 8px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 40px;
    line-height: 56px;
    margin-bottom: 0px;
  }

`;

export const GoalList = styled.ul`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 17px;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    justify-content: center;
    gap: 120px;
  }
`;

export const Goal = styled.li`
  display: flex;
  gap: 16px;
`;

export const Number = styled(Typography).attrs({ type: 'h1', lineHeight: 64, weight: 'bold', color: 'white' })`
  font-size: 64px;
`;

export const GoalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 7px;
  margin-top: 5px;
`;
