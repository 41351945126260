import { Layout } from '@core/UI/Layout';
import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';

import * as UI from './InvestmentConstructionCycle.styles';
import Goal from './components/Goal';
import OptimizationPrinciples from './components/OptimizationPrinciples';
import TotalIndicators from './components/TotalIndicators';
import { IInvestmentConstructionCycleProps } from './types';

const CycleReduce = (props: IInvestmentConstructionCycleProps) => {
  const {
    styles,
  } = props;

  return (
    <>
      <UI.Wrapper styles={styles}>
        <Layout id={LANDING_ANCHORS_ROUTES.INVESTMENT_CONSTRUCTION_CYCLE}>
          <UI.Title>Сокращение инвестиционно-строительного цикла</UI.Title>

          <TotalIndicators />
          <Goal />
        </Layout>
      </UI.Wrapper>
      <OptimizationPrinciples />
    </>
  );
};

export default CycleReduce;
