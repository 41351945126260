import styled from 'styled-components';

import Modal from '@core/UI/Modal';
import VideoPlayer from '@core/components/VideoPlayer';
import { BREAKPOINTS } from '@core/constants/styles';

export const PreviewModal = styled(Modal)<{ withHeader: boolean }>`
  padding-top: ${({ withHeader }) => !withHeader && '64px'};
`;

export const ImagePreview = styled.img`
  min-width: 100%;
  max-width: 80vw;
  max-height: 70vh;
  object-fit: contain;
`;

export const VideoPreview = styled(VideoPlayer)`
  @media ${BREAKPOINTS.DESKTOP_LG} {
    max-height: 70vh;
  }
`;

export const DownloadButtonContainter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-top: 32px;
  }
`;
