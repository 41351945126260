import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';
import { thickScrollStyles } from '@core/styles/scroll.styles';

export const Heading = styled(Typography).attrs({ type: 'h2' })`
  margin-bottom: 16px;
  padding: 22px 0;
  cursor: default;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 22px 24px;
  }
`;

export const Tabs = styled.div`
  display: flex;
  background-color: ${colors.lightGrey};
  padding-left: 2px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Tab = styled.div<{ isActive: boolean }>`
  background-color: ${({ isActive }) => (isActive ? colors.white : 'inherit')};
  border-top: ${({ isActive }) => (isActive ? `1px solid ${colors.blue}` : 0)};
  padding: 16px;
  cursor: pointer;

  ${Typography} {
    color: ${({ isActive }) => (isActive ? colors.riverBlue : colors.manatee)};
  }
`;

export const ActiveTabContent = styled.div`
  padding-top: 16px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding-top: 20px;
  }
`;

export const PreloaderStyles = css`
  color: ${colors.chambrayBlue};
  margin: 32px 0;
  background-color: inherit;
`;

export const DocumentsList = styled.div`
  max-height: 560px;
  
  ${thickScrollStyles};

  @media ${BREAKPOINTS.DESKTOP_LG} {
    max-height: 530px;
    padding: 0 24px;
  }
`;

export const Document = styled.div<{ interactive?: boolean }>`
  display: block;
  padding-bottom: 22px;
  border-bottom: 1px solid ${colors.softGrey};
  margin-bottom: 18px;
  cursor: ${({ interactive = true }) => (interactive ? 'pointer' : 'default')};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const DocumentName = styled(Typography).attrs({
  type: 'p1',
  weight: 'bold',
  color: 'chambrayBlue',
})`
  text-transform: uppercase;
`;

export const DocumentDescription = styled(Typography).attrs({
  type: 'p2',
  color: 'riverBlue',
})`
  margin-top: 4px;
`;
