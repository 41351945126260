import { ReactNode } from 'react';

type TOptimizationPrinciple = {
  id: number;
  text: ReactNode;
};

export const optimizationPrinciples: TOptimizationPrinciple[] = [
  {
    id: 0,
    text: (
      <>
        Нет регламента —
        <br />
        нет процедуры
      </>
    ),
  },
  {
    id: 1,
    text: (
      <>
        Не описан предмет и/
        <br />
        или нет оснований для
        <br />
        отказа — нет процедуры
      </>
    ),
  },
  {
    id: 2,
    text: (
      <>
        Нет срока —
        <br />
        нет процедуры
      </>
    ),
  },
  {
    id: 3,
    text: (
      <>
        Один предмет — одна
        <br />
        процедура (исключе-
        <br />
        ние дублирующих
        <br />
        друг друга процедур)
      </>
    ),
  },
  {
    id: 4,
    text: (
      <>
        Нет влияния на результат —
        <br />
        нет процедуры (исключение
        <br />
        избыточных и устаревших
        <br />
        процедур)
      </>
    ),
  },
];
