import styled, { css } from 'styled-components';

import { ModalStyles } from '@core/UI/Modal/Modal.styles';
import VideoPlayer from '@core/components/VideoPlayer';
import { BREAKPOINTS } from '@core/constants/styles';

export const VideoPreview = styled(VideoPlayer)`
  @media ${BREAKPOINTS.DESKTOP_LG} {
    max-height: 70vh;
  }
`;

export const VideoModalStyles = css`
  ${ModalStyles};

  .eisgs-icon {
    display: none;
  }
`;
