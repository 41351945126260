export const formatFileSizeToMB = (fileSize: number): string => {
  const sizeInMB = fileSize / 1048576;
  return `${sizeInMB.toFixed(2)} Мб.`;
};

export const formatFileSize = (originBytes?: string | number | null, precision: number = 2): string => {
  if (!originBytes) {
    return '';
  }

  let bytes: number;

  if (typeof originBytes === 'string') {
    bytes = +originBytes;
  } else {
    bytes = originBytes;
  }

  const units = ['Б', 'КБ', 'МБ', 'ГБ', 'ТБ', 'ПБ'];

  if (Number.isNaN(parseFloat(String(bytes))) || !Number.isFinite(bytes)) {
    return '';
  }

  let unit = 0;

  while (bytes >= 1024) {
    bytes /= 1024;
    unit += 1;
  }

  return `${Number(bytes.toFixed(precision))} ${units[unit]}`;
};
