export const getFileExtension = (fileName: string) => {
  const substrings = fileName.split('.');
  const hasExtension = substrings.length > 1;

  if (!hasExtension) {
    return '';
  }

  return substrings[substrings.length - 1] || '';
};
