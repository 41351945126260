import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS, DEVICES } from '@core/constants/styles';

import { IContainerStylesProps } from './types';

export const Container = styled.div<IContainerStylesProps>`
  ${({ styles }) => styles};
`;

export const FactorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 16px;
  margin-top: 12px;

  @media ${BREAKPOINTS.TABLET} {
    gap: 32px;
    margin-top: 27px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    flex-direction: row;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    gap: 16px;
  }
`;

export const Factor = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 28px 24px;
  background-color: ${colors.aquaHaze};
  border-radius: 12px;
  text-align: center;

  @media ${BREAKPOINTS.TABLET} {
    padding: 28px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    min-width: 392px;
    padding: 30px 58px;
    width: 100%;

    &:nth-of-type(odd) {
      flex: 0 0 auto;
    }

    &:nth-of-type(2) {
      width: calc(100% - 392px - 40px);
    }

    &:nth-of-type(3) {
      width: 30%;
    }

    @media ${BREAKPOINTS.DESKTOP_LG} {
      &:nth-of-type(2) {
        width: calc(100% - 392px - 16px);
      }
    }
  }
`;

export const FactorTitle = styled(Typography).attrs({ type: 'p2', lineHeight: 24, weight: 'bold' })`
  color: ${colors.prussianBlue};
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 24px;
`;

export const FactorTitleContent = styled(Typography).attrs({ type: 'p2', lineHeight: 24 })`
  margin: auto;
  max-width: 260px;
  font-size: 13px;
  color: ${colors.riverBlue};
  text-transform: uppercase;
  letter-spacing: 1px;

  @media ${BREAKPOINTS.TABLET} {
    height: 48px;
  }
`;

export const FactorsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
    gap: 32px;
  }
`;

export const FactorInfo = styled.div`
  display: flex;
  flex-direction: column;

  @media ${DEVICES.MOBILE} {
    & + & {
      margin-top: 24px;
    }
  }

  @media ${BREAKPOINTS.TABLET} {
    flex: 1 0 auto;
    min-width: 190px;
    width: calc(50% - 32px );
  }

  @media ${BREAKPOINTS.DESKTOP} {
    width: calc(22% - 16px );
    flex-basis: auto;
  }
`;

export const FactorValue = styled(Typography).attrs({ type: 'h2', color: 'chambrayBlue' })`
  b {
    margin-right: -4px;
    font-size: 52px;
    line-height: initial;
  }
`;

export const FactorText = styled(Typography).attrs({ type: 'p1', color: 'riverBlue', lineHeight: 20 })`
  font-size: 18px;
  margin-top: 8px;
`;

export const FactorSummary = styled(Typography).attrs({ type: 'h3', color: 'chambrayBlue', lineHeight: 24 })`
  font-size: 16px;
  margin-top: -8px;
`;

export const FactorComment = styled(Typography).attrs({ type: 'p3', color: 'chambrayBlue', weight: 'bold' })`
  font-style: italic;
  margin-top: 6px;
`;

export const UrbanFactorText = styled(FactorText)`
  margin-top: 0;
  margin-bottom: 16px;
`;

export const UrbanFactorValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    justify-content: space-around;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const UrbanFactorValue = styled(FactorValue)`
  flex-grow: 1;
  margin: auto;

  b {
    font-weight: 700;
    font-size: 40px;
    line-height: 30px;
  }
`;

export const UrbanFactorValueText = styled(FactorValue)`
  margin-left: 10px;
  font-size: 16px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: inline-block;
  }
`;

export const UrbanFactorSummary = styled(Typography).attrs({ type: 'h3', color: 'chambrayBlue' })`
  margin-top: 24px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-top: 16px;
    font-size: 16px;
  }
`;

export const WorkFactorInfo = styled.div`
  margin-top: 16px;

  @media ${BREAKPOINTS.DESKTOP} {
    display: flex;
    gap: 48px;
  }
`;

export const WorkFactorTwo = styled.div`
  & > div:nth-of-type(1) {
    @media ${BREAKPOINTS.TABLET} {
      display: flex;
      gap: 48px;
    }
  }
`;

export const WorkFactorTitleContent = styled(Typography).attrs({ type: 'p2', lineHeight: 24 })`
  font-size: 13px;
  text-transform: uppercase;
  letter-spacing: 1px;
`;

export const WorkFactorVertical = styled.div`
  width: 1px;
  height: 16px;
  margin: auto;
  background: ${colors.blue};
  margin-top: -10px;
  margin-bottom: 8px;

  @media ${BREAKPOINTS.TABLET} {
    height: 32px;
  }
`;

export const WorkFactorBracket = styled.div`
  height: 20px;
  border: 1px solid ${colors.blue};
  border-top: none;
  border-radius: 0 0 16px 16px;
  margin-bottom: 36px;

  &::after {
    content: "";
    width: 1px;
    height: 20px;
    margin: -4px auto;
    display: block;
    background: ${colors.blue};
  }
`;
