import Image from 'next/image';
import Link from 'next/link';
import styled, { css } from 'styled-components';

import { BREAKPOINTS } from '@core/constants/styles';

const MOBILE_PADDING = 12;
const TABLET_PADDING = 32;
const DESKTOP_PADDING = 48;

export const Container = styled.div`
  position: fixed;
  max-width: min(calc(100vw - ${MOBILE_PADDING * 2}px), 280px);
  display: flex;
  box-shadow: -8px -4px 30px 0px #325C9233, 8px 10px 30px 0px #325C921A, 2px -2px 8px 0px #0000001A;
  border-radius: 8px;
  z-index: 101;
  transition: opacity 0.2s, visibility 0.2s;

  ${({ isOpen } : { isOpen: boolean }) => (isOpen ? css`
    opacity: 1;
    visibility: visible;
  ` : css`
    opacity: 0;
    visibility: hidden;
  `)}


  bottom: ${MOBILE_PADDING}px;
  right: ${MOBILE_PADDING}px;

  @media ${BREAKPOINTS.TABLET} {
    bottom: ${TABLET_PADDING}px;
    right: ${TABLET_PADDING}px;

    max-width: 400px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    bottom: ${DESKTOP_PADDING}px;
    right: ${DESKTOP_PADDING}px;
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 0;
  padding: 6px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  svg {
    width: 24px;
    height: 24px;
  }
`;

export const BannerLink = styled(Link)`
  width: 100%;
  height: 100%;
`;

export const BannerImage = styled(Image)`
  width: auto;
  height: auto;
  object-fit: contain;
  border-radius: 8px;
  margin-top: -1px;
`;
