import { memo } from 'react';
import { useSelector } from 'react-redux';

import { Preloader } from '@eisgs/preloader';

import { Typography } from '@core/UI/Typography';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';
import { formatFileSize } from '@core/utils/formatFileSize';
import { getFileExtension } from '@core/utils/getFileExtension';

import { PreloaderStyles } from '../LegalDocuments/LegalDocuments.styles';

import * as UI from './StudyMaterials.styles';
import { selectStudyMaterials } from './store';
import { IStudyMaterialsProps } from './types';

const StudyMaterials = (props: IStudyMaterialsProps) => {
  const { isPublic } = props;
  const {
    data: studyMaterials,
    meta: { fetchLoading },
  } = useSelector(selectStudyMaterials);

  return (
    <UI.Container>
      <UI.Heading>
        Материалы для изучения
      </UI.Heading>

      {fetchLoading && (
        <Preloader
          styles={PreloaderStyles}
        />
      )}

      {studyMaterials && studyMaterials.map(
        ({ id, name, title, size }) => (
          <UI.Material href={isPublic ? getPublicFileUrl(id) : getFileUrl(id)} key={id}>
            <div>
              <UI.FileName>{title || name}</UI.FileName>
              <Typography
                color="cadetBlue"
                type="p2"
              >
                {`${getFileExtension(name)}, ${formatFileSize(size)}`}
              </Typography>
            </div>

            <UI.DownloadIcon />
          </UI.Material>
        ),
      )}
    </UI.Container>
  );
};

export default memo(StudyMaterials);
