import telegramQrImage from '@core/components/AntiCrisisProgram/assets/telegram_qr.png';
import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';

import khusnullinImage from '@features/strategy/assets/Khusnullin.png';

import * as UI from './Quote.styles';

const Quote = () => {
  const isTablet = useMedia(BREAKPOINTS.TABLET);
  const positionText = isTablet
    ? 'Заместитель Председателя Правительства Российской Федерации'
    : 'Заместитель Председателя Правительства РФ';

  return (
    <UI.Quote>
      <UI.QuoteTypography>
        Строительная отрасль и жилищно-коммунальное хозяйство — движущие силы экономики страны, её надежный фундамент.
        Наша ключевая цель — создать комфортную и безопасную среду для жизни граждан нашей страны:
        это и&nbsp;современное жилье, и комфортная городская среда, и качественные дороги.
      </UI.QuoteTypography>

      <UI.NameTypography>
        Марат Шакирзянович Хуснуллин
      </UI.NameTypography>

      <UI.PositionTypography>
        {positionText}
      </UI.PositionTypography>

      <UI.TelegramContainer>
        <UI.TelegramQrCode alt="@mkhusnullin" src={telegramQrImage} />

        <UI.TelegramInfo>
          <UI.TelegramLabel>
            Телеграм-канал
          </UI.TelegramLabel>

          <a href="https://t.me/mkhusnullin" rel="noreferrer" target="_blank">
            <UI.TelegramId>
              @mkhusnullin
            </UI.TelegramId>
          </a>
        </UI.TelegramInfo>
      </UI.TelegramContainer>

      <UI.Khusnullin alt="Khusnullin" src={khusnullinImage} />
    </UI.Quote>
  );
};

export default Quote;
