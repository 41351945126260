import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

export const Container = styled.div<{ styles?: FlattenSimpleInterpolation, isPublic?: boolean }>`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 36px;

  @media ${BREAKPOINTS.TABLET} {
    row-gap: 70px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    ${({ isPublic }) => isPublic && css`
      grid-template-columns: 6.8fr 3.2fr;
    `}
    column-gap: 32px;
  }

  ${({ styles }) => styles};
`;

export const Heading = styled(Typography).attrs({ type: 'h1' })`
  margin-bottom: 24px;
  font-size: 21px;
  line-height: 28px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 28px;
    line-height: 34px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 40px;
    line-height: 50px;
  }
`;
