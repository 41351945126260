import { Typography } from '@core/UI/Typography';

import * as UI from './IndicatorList.styles';
import { IIndicatorProps } from './types';

const IndicatorList = (props: IIndicatorProps) => {
  const { list } = props;

  return (
    <UI.IndicatorList>
      {list.map((indicator) => (
        <UI.Indicator key={indicator.id}>
          {indicator.Icon && <UI.IconContainer>{indicator.Icon}</UI.IconContainer>}
          <Typography color="cadetBlue" transform="uppercase" type="p2">{indicator.title}</Typography>
          <UI.Value color="white">{indicator.from}</UI.Value>
          <UI.Value color="vividTangerine">{indicator.to}</UI.Value>
        </UI.Indicator>
      ))}
    </UI.IndicatorList>
  );
};

export default IndicatorList;
