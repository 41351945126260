import * as UI from './SectionHeader.styles';
import { ISectionHeaderProps } from './types';

const SectionHeader = (props: ISectionHeaderProps) => {
  const {
    children,
    color = 'riverBlue',
  } = props;

  return (
    <UI.SectionHeader color={color}>
      {children}
    </UI.SectionHeader>
  );
};

export default SectionHeader;
