import { Button } from '@core/UI/Button';
import { ModalHeader } from '@core/UI/Modal/Modal.styles';
import { EModalNames } from '@core/UI/Modal/constants';
import { IFileInfo } from '@core/store/modules/materials/types';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';

import { EPreviewMimeTypes, IPreviewProps } from '@features/adminPanel/components/Files/components/Preview/types';

import * as UI from './Preview.styles';

export const checkPreviewMimetype = (file: IFileInfo): boolean => Object
  .values(EPreviewMimeTypes)
  .some((mimetype) => file.mimetype.includes(mimetype));

const Preview = (props: IPreviewProps) => {
  const {
    file,
    isPublic,
    withDownloadButton,
    withHeader = true,
  } = props;

  if (!file) return null;

  const fileUrl = isPublic
    ? getPublicFileUrl(file.id)
    : getFileUrl(file.id);

  return (
    <UI.PreviewModal
      name={EModalNames.PREVIEW_FILE}
      withHeader={withHeader}
    >
      {withHeader && (
        <ModalHeader>
          {file.title || file.name}
        </ModalHeader>
      ) }

      <div>
        {file.mimetype.includes(EPreviewMimeTypes.image) && (
          <UI.ImagePreview src={fileUrl} />
        )}

        {file.mimetype.includes(EPreviewMimeTypes.video) && (
          <UI.VideoPreview
            controls
            url={fileUrl}
          />
        )}
      </div>

      {withDownloadButton && (
        <UI.DownloadButtonContainter>
          <a href={fileUrl}>
            <Button type="filled">
              Скачать
            </Button>
          </a>
        </UI.DownloadButtonContainter>
      )}
    </UI.PreviewModal>
  );
};

export default Preview;
