import * as UI from './VideoPlayer.styles';
import { IVideoPlayerProps } from './types';

const VideoPlayer = (props: IVideoPlayerProps) => {
  const { url, controls, className, styles } = props;

  return (
    <UI.Video className={className} controls={controls} src={url} styles={styles} />
  );
};

export default VideoPlayer;
