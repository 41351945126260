export const goals = [
  {
    id: 0,
    number: '257',
    value: 'с 607 до 350',
    subValue: 'действий',
  },
  {
    id: 1,
    number: '300',
    value: 'с 1300 до 1000',
    subValue: 'дней',
  },
];
