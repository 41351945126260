import { CheckIcon } from '@core/UI/Icons';

import * as UI from './OptimizationPrinciples.styles';
import { optimizationPrinciples } from './data';

const OptimizationPrinciples = () => (
  <UI.Container>
    <UI.Header>
      Принципы оптимизации
    </UI.Header>

    <UI.PrinciplesContainer>
      {optimizationPrinciples.map((principle) => (
        <UI.Principle key={principle.id}>
          <CheckIcon />
          <UI.PrincipleText>
            {principle.text}
          </UI.PrincipleText>
        </UI.Principle>
      ))}
    </UI.PrinciplesContainer>
  </UI.Container>
);

export default OptimizationPrinciples;
