import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

export const Container = styled.div`
  margin: 0px 13px;
  margin-top: -560px;
  padding: 50px 20px 40px 20px;
  background: linear-gradient(249.71deg, ${colors.chambrayBlue} 2.33%, transparent 90.2%), #083158;
  border-radius: 12px;
  overflow: hidden;

  @media ${BREAKPOINTS.TABLET} {
    height: auto;
    padding: 50px 75px 70px 85px;
    margin: 0px 32px;
    margin-top: -405px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    max-width: 1126px;
    margin: -130px auto 0px auto;
    padding: 28px 31px 0px 31px;
  }
`;

export const Header = styled(Typography).attrs({ type: 'h2', color: 'white' })`
  text-align: center;
`;

export const PrinciplesContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 55px 50px;
  margin-top: 53px;
  flex-wrap: wrap;

  @media ${BREAKPOINTS.TABLET} {
    gap: 75px 66px;
    margin-top: 55px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    gap: 40px 66px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    gap: 66px;
    margin-top: 37px;
  }
`;

export const Principle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: calc(50% - 25px);

  &:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -15px;
    width: 1px;
    height: 155px;
    background-color: ${colors.vividTangerine};
    transform: rotate(8deg);

    @media ${BREAKPOINTS.DESKTOP} {
      right: -27px;
    }
  }

  &:nth-child(even)::after {
    display: none;
  }

  &:last-child {
    width: 80%;

    @media ${BREAKPOINTS.TABLET} {
      width: auto;
    }
  }

  @media ${BREAKPOINTS.TABLET} {
    width: auto;
    flex-wrap: nowrap;
    flex: 1 0 auto;

    &:nth-child(even)::after {
      display: block;
    }

    &:nth-child(3)::after {
      display: none;
    }

    &:nth-child(4) {
      margin-left: 10%;
    }
  }

  @media ${BREAKPOINTS.DESKTOP} {
    padding-bottom: 14px;

    &:nth-child(3)::after {
      display: block;
    }

    &:nth-child(4) {
      margin-left: 0px;
    }
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    &:nth-child(4)::after {
      display: block;
    }
  }
`;

export const PrincipleText = styled(Typography).attrs({ type: 'p1', color: 'white' })`
  margin-top: 18px;

  br {
    display: none;

    @media ${BREAKPOINTS.TABLET} {
      display: inline-block;
    }
  }
`;
