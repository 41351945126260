export enum EStaticFilesIds {
  STRATEGY = 7,
  STRATEGY_MAIN_FACTORS = 9,
  USER_GUIDE = 11,
  ICC_SUMMARY_LIST = 231,
  ANTI_CRISIS_PROGRAM = 330,
  ANTI_CRISIS_VIDEO_1 = 2263,
  ANTI_CRISIS_VIDEO_2 = 2675,
  STRATEGY_REPORT = 700,
}
