import Documents from '@features/landing/assets/icons/documents.svg';
import Materials from '@features/landing/assets/icons/materials.svg';
import Planning from '@features/landing/assets/icons/planning.svg';
import Presentation from '@features/landing/assets/icons/presentation.svg';

import { ICycleIndicator } from './types';

export const indicators: ICycleIndicator[] = [
  {
    id: 0,
    title: 'Согласований',
    from: '26',
    to: '21',
    Icon: <Planning />,
  },
  {
    id: 1,
    title: 'Материалов',
    from: '64',
    to: '43',
    Icon: <Materials />,
  },
  {
    id: 2,
    title: 'Документов',
    from: '838',
    to: '515',
    Icon: <Documents />,
  },
  {
    id: 3,
    title: 'Сведений',
    from: '61',
    to: '28',
    Icon: <Presentation />,
  },
];

export const totalIndicators: ICycleIndicator[] = [
  {
    id: 0,
    title: 'Действия',
    from: '989',
    to: '607',
  },
  {
    id: 0,
    title: 'Дни',
    from: '1831',
    to: '1300',
  },
];
