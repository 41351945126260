import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

export const IndicatorList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  align-items: start;
  justify-content: space-between;
  gap: 10px 20px;

  @media ${BREAKPOINTS.TABLET} {
    gap: 20px;
    flex-wrap: nowrap;
  }
`;

export const Indicator = styled.li`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 120px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: 190px;
  }
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 64px;
  background-color: rgba(59, 110, 163, 0.4);
  border-radius: 24px;
`;

export const Value = styled(Typography).attrs({ type: 'p1', weight: 'bold', lineHeight: 64 })`
  font-size: 64px;
`;
